
  // 存储 token 到 localStorage
  export const setToken = (token) => {
    localStorage.setItem('token', token);
  };
  
  // 从 localStorage 中获取 token
  export const getToken = () => {
    return localStorage.getItem('token');
  };
  
  // 从 localStorage 中删除 token
  export const removeToken = () => {
    localStorage.removeItem('token');
  };