<template>
  <div>
    <router-view></router-view>
    <van-tabbar
      route
      active-color="#4050B5"
      inactive-color="#666"
    >
      <van-tabbar-item to="/home" :icon="$url == '/home' ? icon.active : icon.svgUrl"
        >首页</van-tabbar-item
      >
      <van-tabbar-item
        to="/scancode"
        :icon="$url == '/scancode' ? icon1.active : icon1.svgUrl"
        >扫码</van-tabbar-item
      >
      <van-tabbar-item to="/card" :icon="$url == '/card' ? icon2.active : icon2.svgUrl"
        >购物车</van-tabbar-item
      >
      <van-tabbar-item to="/my" :icon="$url == '/my' ? icon3.active : icon3.svgUrl"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
// import Quagga from 'quagga';
export default {
  name: "layOut",
  data() {
    return {
      tabberHeight: 50,
      // active:localStorage.getItem('activeTab') || '',
      icon: {
        active: require("@/assets/首页激活.svg"),
        svgUrl: require("@/assets/首页未激活.svg"), // 请修改为自己的svg图片路径
      },
      icon1: {
        active: require("@/assets/扫码激活.svg"),
        svgUrl: require("@/assets/扫码未激活.svg"), // 请修改为自己的svg图片路径
      },
      icon2: {
        active: require("@/assets/购物车激活.svg"),
        svgUrl: require("@/assets/购物车未激活.svg"), // 请修改为自己的svg图片路径
      },
      icon3: {
        active: require("@/assets/我的激活.svg"),
        svgUrl: require("@/assets/我的未激活.svg"), // 请修改为自己的svg图片路径
      },
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkBottomGesture)
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    checkBottomGesture() {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    if (windowHeight < screen.availHeight) {
      // 底部有横杠手势操作
      this.tabberHeight = 80
    } else {
      // 恢复默认高度
      this.tabberHeight = 50
    }
  },
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 在页面滚动时根据条件判断是否需要修改tabber的高度
      // 可以根据页面滚动的位置来判断是否需要增加tabber的高度
      if (scrollTop > 70) {
        this.tabberHeight = 80; // 修改tabber的高度
      } else {
        this.tabberHeight = 50; // 恢复默认的tabber高度
      }
    },
  },
  destroyed() {
    window.addEventListener('resize', this.checkBottomGesture)
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* ::v-deep .van-hairline--top-bottom {
  height: 70px;
} */
/* ::v-deep .van-tabbar-item--active {
  margin-bottom: 15px;
} */
/* ::v-deep .van-tabbar-item {
  margin-bottom: 15px;
} */
::v-deep .van-tabbar-item__text {
  font-size: 10px;
}
::v-deep .van-icon img {
  width: 24px;
  height: 24px;
}
</style>
