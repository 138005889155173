import '@/style/reset.css';
import 'lib-flexible/flexible';
import moment from 'moment';
import Vant from 'vant';
import 'vant/lib/index.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index'; // 引入 Vuex store
Vue.config.productionTip = false
Vue.use(Vant)
Vue.prototype.$moment = moment


/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  Vue.prototype.$url = to.fullPath
  if (to.meta.title) {
    document.title = to.meta.title
    next()
    if (to.path !== from.path) {
      // 执行页面导航操作
      next();
    } else {
      // 可以选择不执行跳转，或者执行其他逻辑
    }
  }
  
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')