import Vue from 'vue';
import Vuex from 'vuex';
import {setToken,getToken} from '@/utils/storage'

Vue.use(Vuex);

const store = new Vuex.Store({
    state() {
        return {
            token:getToken() || '',
            username:localStorage.getItem('username') || '',
            phone:localStorage.getItem('phone') || '',
            avatar:localStorage.getItem('avatar') || '',
        }
    },
    mutations: {
        // 定义修改状态的方法
        setToken(state, token){
            state.token = token
            setToken(token)
        },
        username(state, username){
            state.username = username
            localStorage.setItem('username',username)
        },
        phone(state, phone){
            state.phone = phone
            localStorage.setItem('phone',phone)
        },
        avatar(state, avatar){
            state.avatar = avatar
            localStorage.setItem('avatar',avatar)
        }
    },
    actions: {
        // 定义触发 mutations 的行为
    },
    getters: {
        // 定义从状态派生出来的一些状态
    }
});
export default store;