import Layout from '@/Layout'
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home/',
    children: [
      {
        path: '/home/',
        component: () => import('@/views/Home/index'),
        meta: { title: localStorage.getItem('shopName') ? localStorage.getItem('shopName') : '乐排排' },
        hidden: true
      },
      {
        path: '/scancode//',
        component: () => import('@/views/ScanCode/index'),
        meta: { title: '扫码' },
        hidden: true
      },
      {
        path: '/card/',
        component: () => import('@/views/Cart/index'),
        meta: { title: '购物车' },
        hidden: true
      },
      {
        path: '/my/',
        component: () => import('@/views/My/index'),
        meta: { title: '我的' },
        hidden: true
      }
    ]
  },
  {
    path: '/login/',
    component: () => import('@/views/Login/index'),
    meta: { title: '乐排排' },
    hidden: true
  },
  {
    path: '/orders/',
    component: () => import('@/views/Orders/index'),
    meta: { title: '提交订单' },
    hidden: true
  },
  {
    path: '/details/',
    component: () => import('@/views/Details/index'),
    meta: { title: '商品详情' },
    hidden: true
  },
  {
    path: '/bankcards/',
    component: () => import('@/views/BankCards/index'),
    meta: { title: '银行卡' },
    hidden: true
  },
  {
    path: '/purse/',
    component: () => import('@/views/Purse/index'),
    meta: { title: '我的钱包' },
    hidden: true
  },
  {
    path: '/coupons/',
    component: () => import('@/views/Coupons/index'),
    meta: { title: '优惠券' },
    hidden: true
  },
  {
    path: '/address/',
    component: () => import('@/views/Address/index'),
    meta: { title: '收货地址' },
    hidden: true
  },
  {
    path: '/addaddress/',
    component: () => import('@/views/addAddress/index'),
    meta: { title: '新增收货地址' },
    hidden: true
  },
  {
    path: '/realname/',
    component: () => import('@/views/RealName/index'),
    meta: { title: '实名认证' },
    hidden: true
  },
  {
    path: '/setup/',
    component: () => import('@/views/SetUp/index'),
    meta: { title: '基础资料设置' },
    hidden: true
  },
  {
    path: '/nickname/',
    component: () => import('@/views/Nickname/index'),
    meta: { title: '修改昵称' },
    hidden: true
  },
  {
    path: '/mobile/',
    component: () => import('@/views/Mobile/index'),
    meta: { title: '修改手机号' },
    hidden: true
  },
  {
    path: '/receiving/',
    component: () => import('@/views/Receiving/index'),
    meta: { title: '订单详情' },
    hidden: true
  },
  {
    path: '/allorders/',
    component: () => import('@/views/Allorders/index'),
    meta: { title: '我的订单' },
    hidden: true
  },
  {
    path: '/tiethecard/',
    component: () => import('@/views/TietheCard/index'),
    meta: { title: '绑定银行卡' },
    hidden: true
  },
  {
    path: '/addressbackup/',
    component: () => import('@/views/AddressBackup/index'),
    meta: { title: '收货地址' },
    hidden: true
  },
  {
    path: '/scancodepay/',
    component: () => import('@/views/ScanCodePay/index'),
    meta: { title: localStorage.getItem('shopName') ? localStorage.getItem('shopName') : '乐排排' },
    hidden: true
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
